import gql from 'graphql-tag'


const orderResponse = `
  id type name parentId
  qty price totalPrice
  unitId unit {id name}
  stocks {
    id qty docId parentId orderId isPrinted
    inventoryId inventory {
      id code name
      unitId unit {id name}
    }
    createdAt createdBy createdUser {id name}
    approvedAt approvedBy approvedUser {id name}
  }
  ledgers {
    id docId stockId orderId amount
    accountId account {id code name}
    createdAt createdBy createdUser {id name}
    approvedAt approvedBy approvedUser {id name}
  }
`

export const LIST_ORDER = (templateType) => gql`query LIST_ORDER ($docType: String!, $docId: Int!) {
  orders: listDoc${templateType}Order (docType: $docType, docId: $docId) {${orderResponse}}
}`

export const CREATE_ORDER = (templateType) => gql`mutation CREATE_ORDER ($docType: String!, $docId: Int!, $input: DocSaleEquipmentOrderInput!) {
  createOrder: createDoc${templateType}Order (docType: $docType, docId: $docId, input: $input) {${orderResponse}}
}`

export const DESTROY_ORDER = (templateType) => gql`mutation DESTROY_ORDER ($docType: String!, $orderId: Int!) {
  destroyOrder: destroyDoc${templateType}Order (docType: $docType, orderId: $orderId) {id}
}`

export const LIST_ORDER_INVENTORY = (templateType) => gql`query LIST_ORDER_INVENTORY ($docType: String!, $q: FilterInput) {
  inventories: listDoc${templateType}OrderInventory (docType: $docType, q: $q) {
    id type name
    inventoryId inventory {
      id type code name unitId
      unit {id name}
    }
    children {
      id type docType name parentId
      qty amountRatio
      inventoryId inventory {
        id type code name unitId
        unit {id name}
      }
      accountId account {id code name}
    }
  }
}`
